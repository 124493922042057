import React from "react";
import Lottie from "react-lottie";
import loaderAnimation from "../../../public/lotties/loader.json";

import style from "./loader.module.css";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={style.container}>
      <Lottie options={defaultOptions} height={80} width={80} />
    </div>
  );
};

export default Loader;
